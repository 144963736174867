import { createStore } from "vuex";

export default createStore({
  state: {
    projects: [],
    customers: [],
    engineers: [],
    hourentries: [],
    user: null,
  },
  getters: {},
  mutations: {
    addResources(state, data) {
      state[data.type] = [...data.resources];
    },

    pushResource(state, data) {
      state[data.type].push(data.resource);
    },

    setUser(state, user) {
      state.user = user;

      if (user !== null) {
        window.localStorage.setItem("pstmSession", user.lastLogin);
        window.localStorage.setItem("pstmUser", JSON.stringify(user));
      } else {
        window.localStorage.removeItem("pstmSession");
        window.localStorage.removeItem("pstmUser");
      }
    },
  },
  actions: {},
  modules: {},
});
