<template>
  <div id="changePassword" class="popUp">
    <div class="overlay" @click="$emit('closePopUp', true)"></div>
    <div class="content">
      <span
        class="hasIcon close float-right"
        @click="$emit('closePopUp', true)"
      >
      </span>
      <span class="title noMargin">Change Password</span>
      <hr />
      <form @submit.prevent>
        <div class="input">
          <label for="userName">New Password</label>
          <input
            type="password"
            id="newPassword"
            v-model="newPassword"
            @keyup="passwordMismatch && checkPassword()"
            @focusout="checkPassword()"
          />
        </div>
        <div class="input">
          <label for="password">Repeat Password</label>
          <input
            type="password"
            id="password"
            v-model="repeatPassword"
            @keyup="passwordMismatch && checkPassword()"
            @focusout="checkPassword()"
          />
        </div>

        <template v-if="passwordMismatch">
          <hr />
          <div class="dialog alert">
            <p class="smallText">
              The entered passwords do not match. Please check and try again!
            </p>
          </div>
        </template>
        <button class="btn primary" @click="changePassword()">
          Change Password
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/lib/api";

export default {
  data() {
    return {
      newPassword: "",
      repeatPassword: "",
      passwordMismatch: false,
      show: false,
    };
  },

  props: ["engineerId"],

  methods: {
    changePassword() {
      api
        .put("engineers", this.engineerId + "/" + this.newPassword)
        .then(() => {
          alert("The password has been changed successfuly!");
          this.newPassword = this.repeatPassword = "";
          this.$emit("closePopUp", true);
        })
        .catch((error) => {
          console.log(error);
          alert("Sorry! The request could not be processed. Please try again!");
        });
    },

    checkPassword() {
      this.passwordMismatch =
        this.newPassword.length &&
        this.repeatPassword.length &&
        this.newPassword !== this.repeatPassword;
    },
  },
};
</script>
