import axios from "axios";
const baseURL = process.env.VUE_APP_REST_API_URL;

const resources = {
  customers: "/customers",
  engineers: "/engineers",
  projects: "/projects",
  hourentries: "/hourentries",
  login: "/engineers/login",
};

export default {
  get(resource) {
    return axios.get(baseURL + resources[resource]);
  },

  getFiltered(resource, filter) {
    return axios.get(baseURL + resources[resource] + "/" + filter);
  },

  post(resource, data) {
    return axios.post(baseURL + resources[resource], data);
  },

  put(resource, id, data) {
    return axios.put(baseURL + resources[resource] + "/" + id, data);
  },

  delete(resource, data) {
    return axios.delete(baseURL + resources[resource] + "/" + data);
  },
};
