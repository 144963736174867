import { createRouter, createWebHistory } from "vue-router";
import ManageResources from "@/components/ManageResources.vue";

const routes = [
  {
    path: "/resources/:resourceType",
    name: "ManageResources",
    component: ManageResources,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
