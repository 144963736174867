<script setup>
import { RouterView } from "vue-router";
import SideBar from "@/components/nav/SideBar.vue";
import LogIn from "@/components/LogIn.vue";
import store from "./store";
import moment from "moment";
</script>

<template>
  <template v-if="loggedIn">
    <SideBar />
    <router-view :key="$route.path" />
  </template>
  <template v-else>
    <LogIn />
  </template>
</template>

<script>
export default {
  computed: {
    loggedIn() {
      if (
        localStorage.getItem("pstmSession") !== null &&
        localStorage.getItem("pstmUser") !== null
      ) {
        const hours = moment().diff(
          moment(localStorage.getItem("pstmSession")),
          "hours"
        );

        if (hours < 24) {
          const user = JSON.parse(window.localStorage.getItem("pstmUser"));
          store.commit("setUser", user);
        } else {
          alert("Your session has expired, you'll be logged out!");
          store.commit("setUser", null);
        }
      }

      return store.state.user != null;
    },
  },
};
</script>

<style lang="scss">
#app {
  background: var(--backgroundColor);
  width: 100vw;
  height: 100vh;
  display: block;
}
</style>
